const experienceData = [
  {
    company: 'Housecall Pro',
    position: 'Senior Software Engineer',
    location: 'Remote',
    tenure: 'April 2021 - present',
    description: 'Taking lead on the team\'s most critical initiatives and complex bugs, acting as the domain expert for cross-functional squads who leverage Reporting features, and mentoring junior and onboarding engineers to ensure their growth and success.'
  },
  {
    company: 'Thesis',
    position: 'Conversion Rate Optimization Specialist',
    location: 'Brooklyn, NY',
    tenure: 'June 2018 - February 2020',
    description: 'Managed landing page conversion rate optimization for eight clients. Partnered with Thesis’ design and engineering teams to ensure smooth implementation of landing pages and features built to drive conversion. Worked with lead front-end engineers to develop a company-wide QA process for new landing pages and features.'
  },
  {
    company: 'Jackson Hole Mountain Resort',
    position: 'Rental Shop Manager',
    location: 'Jackson, WY',
    tenure: 'November 2015 - March 2018',
    description: 'Directed the operations and business of three retail and rental ski shops that each had a seasonal revenue of approximately $500,000. Hired, trained, scheduled and supervised a staff of 20 full-time and part-time employees.'
  }
]

const educationData = [
  {
    school: 'Turing School of Software and Design',
    location: 'Remote',
    degree: 'Certificate in Front-End Software Engineering',
    tenure: 'June 2020 - March 2021'
  },
  {
    school: 'St. Lawrence University',
    location: 'Canton, NY',
    degree: 'B.A. with majors in English and Environmental Studies',
    tenure: 'August 2011 - May 2015'
  }
]

const skillsData = {
  programmingLanguages: [
    'TypeScript,',
    'Ruby on Rails,',
    'SQL,',
    'CSS,',
    'HTML'
  ],
  frameworksAndLibraries: [
    'React,',
    'Redux,',
    'React Testing Library,',
    'Material UI,',
    'Rspec'
  ],
  otherSkills: [
    'Gitlab,',
    'Sentry,',
    'Segment,',
    'Amplitude,',
    'Google Analytics,',
    'LaunchDarkly'
  ]
}

export { experienceData, educationData, skillsData }